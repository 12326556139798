import { Button, CircularProgress } from "@mui/material";
const SubmitButton = ({ btnText, isLoading }) => {
  if (isLoading === true) {
    return (
      <CircularProgress
        color="primary"
        sx={{
          margin: "0 auto",
          display: "flex",
          marginTop: 5,
          marginBottom: 5,
        }}
      />
    );
  } else {
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{
          margin: "0 auto",
          display: "flex",
          marginTop: 5,
          marginBottom: 5,
          borderRadius: 4,
        }}
      >
        {btnText}
      </Button>
    );
  }
};

export default SubmitButton;
